import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "app/app.state";
import { generatState } from "./general.state";


const generalFeatureSelector = createFeatureSelector<generatState>('general')

export const selectCategories = createSelector(generalFeatureSelector, (state) => {
    return state.categories
})

export const selectEnergies = createSelector(generalFeatureSelector, (state) => {
    return state.energies
})

export const selectArtists = createSelector(generalFeatureSelector, (state) => {
    return state.artists
})

export const selectChannel = createSelector(generalFeatureSelector, (state) => {
    return state.channels
})

export const selectRightsHolder = createSelector(generalFeatureSelector, (state) => {
    return state.rightsholders
})

export const selectcontract = createSelector(generalFeatureSelector, (state) => {
    return state.contracts
})

export const selectClip = createSelector(generalFeatureSelector, (state) => {
    return state.clips
})
import { Action, createReducer, on } from '@ngrx/store';
import { generalIntialState } from './general.state';
import { dummyAction, fetchArtists, fetchCategory, fetchChannel, fetchClips, fetchContract, fetchEnergies, fetchrightHolder, getCategory } from './general.actions';

const _generalReducer = createReducer(generalIntialState,
    on(fetchCategory, (state,action) => {
        return { ...state, categories : action.categories }
    }), 
    on(dummyAction, (state) => {
        return {...state}
    }),
    on(fetchEnergies,(state,action) => {
        return { ...state, energies : action.energies}
    }),
    on(fetchArtists, (state,action) => {
        return { ...state, artists : action.artists}
    }),
    on(fetchChannel, (state,action) => {
        return { ...state, channels : action.channels}
    }),
    on(fetchrightHolder, (state,action) => {
        return { ...state, rightsholders : action.rightsholders}
    }),
    on(fetchContract, (state,action) => {
        return { ...state, contracts : action.contracts}
    }),
    on(fetchClips, (state,action) => {
        return { ...state, clips : action.clips}
    }),
    
)

export function generalReducer(state, action) {
    return _generalReducer(state, action);
}
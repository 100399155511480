import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { exhaustMap, map, withLatestFrom } from "rxjs/operators";
import { dummyAction, fetchArtists, fetchCategory, fetchChannel, fetchClips, fetchContract, fetchEnergies, fetchrightHolder, getArtists, getCategory, getChannel, getClips, getContract, getEnergies, getrightHolder } from "./general.actions";
import { CommonService } from "app/core/service/common/common.service";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.state";
import { selectArtists, selectCategories, selectChannel, selectClip, selectEnergies, selectRightsHolder, selectcontract } from "./general.selectors";
import { of } from "rxjs";

@Injectable()
export class GeneralEffects {
    constructor(
        private actions$: Actions,
        private commonservice: CommonService,
        private store: Store<AppState>
    ) { }

    loadCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCategory),
            withLatestFrom(this.store.select(selectCategories)),
            exhaustMap(([action, categories]) => {
                const params = {
                    search: "",
                    category: ""
                }
                if (!categories.length) {
                    return this.commonservice.getallcategory(params).pipe(
                        map((data: any) => {
                            return fetchCategory({ categories: data?.result })
                        }
                        ))
                }
                return of(dummyAction())
            })
        )
    });

    loadEnergies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEnergies),
            withLatestFrom(this.store.select(selectEnergies)),
            exhaustMap(([action, energies]) => {
                const params = {
                    search: "",
                }
                if (!energies.length) {
                    return this.commonservice.getallenergyleavel(params).pipe(
                        map((data: any) => {
                            return fetchEnergies({ energies: data?.result })
                        }
                        ))
                }
                return of(dummyAction())
            })
        )
    });

    loadArtists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getArtists),
            withLatestFrom(this.store.select(selectArtists)),
            exhaustMap(([action, artists]) => {
                const params = {
                    search: "",
                }

                return this.commonservice.getallartist().pipe(
                    map((data: any) => {
                        return fetchArtists({ artists: data?.result })
                    }
                    ))

               
            })
        )
    });

    loadChannel$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChannel),
            withLatestFrom(this.store.select(selectChannel)),
            exhaustMap(([action, channels]) => {
                const params = {
                    search: "",
                }
                if (!channels.length) {
                    return this.commonservice.getallchannel({ search: "" }).pipe(
                        map((data: any) => {
                            return fetchChannel({ channels: data?.result })
                        }
                        ))
                }
                return of(dummyAction())
            })
        )
    });

    loadRightHolders$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getrightHolder),
            withLatestFrom(this.store.select(selectRightsHolder)),
            exhaustMap(([action, rightholders]) => {
                const params = {
                    search: "",
                }
                if (!rightholders.length) {
                    return this.commonservice.getall({ type: 4 }).pipe(
                        map((data: any) => {
                            return fetchrightHolder({ rightsholders: data?.result })
                        }
                        ))
                }
                return of(dummyAction())
            })
        )
    });

    loadcontract$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getContract),
            withLatestFrom(this.store.select(selectcontract)),
            exhaustMap(([action, contracts]) => {
                const params = {
                    search: "",
                };
                if (!contracts.length) {

                    return this.commonservice.getallcontracts(params).pipe(
                        map((data: any) => {
                            return fetchContract({ contracts: data?.result })
                        }
                        ))
                }
                return of(dummyAction())
            })
        )
    });

    loadclip$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getClips),
            withLatestFrom(this.store.select(selectClip)),
            exhaustMap(([action, clips]) => {
                if (!clips.length) {
                    return this.commonservice.getclip().pipe(
                        map((data: any) => {
                            return fetchClips({ clips: data?.result })
                        }
                        ))
                }
                return of(dummyAction())
            })
        )
    });
}
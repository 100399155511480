import { RouteInfo } from "./vertical-menu.metadata";
import { Roles } from 'app/roles';

const { ADMIN, MANAGER, MANAGER_INSIGHTS, CREATOR, RIGHT_HOLDER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED } = Roles;

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Dashboard",
    icon: "ft-arrow-right submenu-icon",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/dashboard-form",
        title: "Dashboard",
        icon: "ft-home",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      },
      {
        path: "/dashboard",
        title: "Dashboard ",
        icon: "ft-home",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      },
      {
        path: "",
        title: "Weekly Overview",
        icon: "ft-home",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
        submenu: [
          {
            path: "/dashboard-graphs",
            title: "Dashboard",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
          },
          {
            path: "/dashboard-channel",
            title: "Channel",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
          },
          {
            path: "/dashboard-podcast",
            title: "Podcast",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
          },
          {
            path: "/dashboard-social-media",
            title: "Social Media",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
          },
          {
            path: "/dashboard-shop",
            title: "Shop",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
          },
          {
            path: "/dashboard-subscriber",
            title: "Subscriber",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
          }
        ],
      }],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
  },

  {
    path: "/creators/profile",
    title: "Profile",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },
  {
    path: "/right-holders/profile",
    title: "Profile",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [RIGHT_HOLDER],
  },
  {
    path: "/admins",
    title: "Admins",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN],
  },
  {
    path: "/right-holders",
    title: "Signed Speakers & Partners",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },
  {
    path: "/artists",
    title: "Speakers",
    icon: "ft-mic",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/contracts",
    title: "All Agreements",
    icon: "ft-clipboard",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },
  {
    path: "/contracts",
    title: "All Agreements",
    icon: "ft-users",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [CREATOR],
    submenu: [],
  },
  {
    path: "/channels",
    title: "YouTube Channels",
    icon: "ft-video",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
  },
  {
    path: "",
    title: "Content Library",
    icon: "ft-play",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/content-library/speakers",
        title: "Speeches",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      },

      {
        path: "/content-library/type/music",
        title: "Music",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      }, {
        path: "/content-library/type/footage",
        title: "Footage",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR, RIGHT_HOLDER],
      }, {
        path: "/drm-content",
        title: "DRM Content",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "/import-youtube-content",
        title: "Import Youtube Content",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      }
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },
  {
    path: "",
    title: "Content Settings",
    icon: "ft-settings",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/energy-level",
        title: "Energy Level Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/categories",
        title: "Category Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },

  {
    path: "/content-library/my-library",
    title: "My Library",
    icon: "ft-play",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR, RIGHT_HOLDER],
  },

  {
    path: "/browse",
    title: "Browse",
    icon: "ft-shopping-cart",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },
  {
    path: "",
    title: "Revenue",
    icon: "ft-message-square",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "royalties-earnings",
        title: "Overview",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, VIEWER, CREATOR, RIGHT_HOLDER],
      },
      {
        path: "royalties/by-right-holders",
        title: "By Signed Speakers",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, VIEWER, CREATOR],
      },
      {
        path: "royalties/by-videos",
        title: "By Youtube Videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, VIEWER, CREATOR, RIGHT_HOLDER],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_INSIGHTS, VIEWER, CREATOR, RIGHT_HOLDER],
  },

  {
    path: "",
    title: "Payments",
    icon: "ft-dollar-sign",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/payment-setting",
        title: "Payment Setting",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN],
      },
      {
        path: "",
        title: "Payment History",
        icon: "ft-arrow-right submenu-icon",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
          {
            path: "payment-history/landing-page",
            title: "Overview",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN],
          },
          {
            path: "payment-history/by-channel",
            title: "By Channel",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED],
          },
          {
            path: "payment-history/by-right-holders",
            title: "By Signed Speakers",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN],
          },

          {
            path: "payment-history/by-videos",
            title: "By Youtube videos",
            icon: "ft-arrow-right submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            hasaccess: [ADMIN],
          },
        ],
        hasaccess: [ADMIN],
      },
    ],
    hasaccess: [ADMIN],
  },

  {
    path: "",
    title: "Payments",
    icon: "ft-dollar-sign",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "payment-history/landing-page",
        title: "Overview",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR, RIGHT_HOLDER],
      },
      {
        path: "payment-history/by-right-holders",
        title: "By Signed Speakers",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR],
      },
      {
        path: "payment-history/by-channel",
        title: "By Channel",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR],
      },
      {
        path: "payment-history/by-videos",
        title: "By Youtube videos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [CREATOR, RIGHT_HOLDER],
      },
    ],
    hasaccess: [CREATOR, RIGHT_HOLDER],
  },


  {
    path: "/contact-us",
    title: "Messages",
    icon: "ft-mail",
    class: "",
    badge: '', badgeClass: '',
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },
  {
    path: "https://mindsetdrm.com/faq-speakers/",
    title: "FAQ",
    icon: "ft-external-link",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: true,
    submenu: [],
    hasaccess: [CREATOR],
  },
];

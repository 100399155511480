import { Artist } from "./models/artist.model"
import { Category } from "./models/category.model"
import { Channel } from "./models/channel.model"
import { Clip } from "./models/clips.model"
import { Contract } from "./models/contract.model"
import { Energy } from "./models/energy.model"
import { RightHolder } from "./models/rightholder.model"

export interface generatState {
    categories : Category[],
    energies : Energy[],
    artists : Artist[],
    channels : Channel[],
    rightsholders : RightHolder[],
    contracts : Contract[],
    clips : Clip[]
}

export const generalIntialState : any = {
    categories : [],
    energies : [],
    artists : [],
    channels : [],
    rightsholders : [],
    contracts : [],
    clips : []
}
import { createAction, props } from '@ngrx/store';
import { Category } from './models/category.model';
import { Energy } from './models/energy.model';
import { Artist } from './models/artist.model';
import { Channel } from './models/channel.model';
import { RightHolder } from './models/rightholder.model';
import { Contract } from './models/contract.model';
import { Clip } from './models/clips.model';

export const getCategory = createAction('getCategory');
export const fetchCategory = createAction('fetchCategory', props<{ categories : Category[] }>());

export const getEnergies = createAction('getEnergies');
export const fetchEnergies = createAction('fetchEnergies', props<{ energies : Energy[] }>());

export const getArtists = createAction('getArtists');
export const fetchArtists = createAction('fetchArtists', props<{ artists : Artist[] }>());

export const getChannel = createAction('getChannel');
export const fetchChannel = createAction('fetchChannel', props<{ channels : Channel[] }>());

export const getrightHolder = createAction('getrightHolder');
export const fetchrightHolder = createAction('fetchrightHolder', props<{ rightsholders : RightHolder[] }>());

export const getContract = createAction('getContract');
export const fetchContract = createAction('fetchContract', props<{ contracts : Contract[] }>());

export const getClips = createAction('getClips');
export const fetchClips = createAction('fetchClips', props<{ clips : Clip[] }>());

export const dummyAction = createAction("dummyAction");